import { Button, Form, Input, notification } from "antd"
import React, { useState } from "react"
import Image from "next/image"

import { signIn, sendPasswordResetEmail } from "supertokens-auth-react/recipe/emailpassword"

import { Apple, Google } from "@css/icons/SVG"
import logo from "@public/images/logo34x24@2x.png"

import styles from "@css/modules/Layout.module.scss"
import settingsStyles from "@css/modules/Settings.module.scss"
import { passRules } from "./validation"
import illustration from "@public/images/loginIllustration.jpg"

export function SignInPanel({ isMobile, thirdPartySignInUp, toggleSignInUp, onClose }) {
    const [form] = Form.useForm()
    const [state, setState] = useState({ showForgotPassword: false })
    const [isLoading, setIsLoading] = useState(false)

    const onFinish = async (values) => {
        try {
            let response
            setIsLoading(true)
            if (state.showForgotPassword) {
                response = await sendPasswordResetEmail({
                    formFields: [
                        {
                            id: "email",
                            value: values.email
                        }
                    ]
                })
            } else {
                response = await signIn({
                    formFields: [
                        {
                            id: "email",
                            value: values.email
                        },
                        {
                            id: "password",
                            value: values.password
                        }
                    ]
                })
            }

            if (response.status === "FIELD_ERROR") {
                const invalidFields = response.formFields.map((i) => {
                    return {
                        name: i.id,
                        value: values[i.id],
                        errors: [i.error]
                    }
                })
                form.setFields(invalidFields)
            } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
                notification.open({
                    message: "Email password combination is incorrect.",
                    type: "warning"
                })
            } else if (response.status === "OK") {
                // sign up successful. The session tokens are automatically handled by
                // the frontend SDK.
                // reset password email sent.
                if (state.showForgotPassword) {
                    notification.open({
                        message: "Please check your email for the password reset link",
                        type: "info"
                    })
                    localStorage.setItem("redirectAfterLogin", location.pathname + location.search)
                    forgotPassword() //toggle back
                } else {
                    localStorage.setItem("hasLoggedInBefore", "1")
                    notification.open({
                        message: "You're now logged in.",
                        type: "success"
                    })
                }

                form.resetFields()
                onClose()
            }
        } catch (err) {
            notification.open({
                message: "Sign-In Error. Try again.",
                type: "info",
                description: err.message
            })
        } finally {
            setIsLoading(false)
        }
    }
    const onFinishFailed = () => {}
    const forgotPassword = () => {
        // toggle password view
        setState({ showForgotPassword: !state.showForgotPassword })
    }

    return (
        <>
            {!isMobile && (
                <div className={styles.leftSide}>
                    <Image src={logo} alt="DVC For Less logo" className={styles.loginModalLogo} />
                    <div className={styles.leftSideContent}>
                        <h3 className={styles.title}>The easiest way to shop the entire DVC market</h3>
                        <p>The ultimate tool for finding the contract of your dreams</p>
                        <Image src={illustration} alt="login illustration" width="286" height="252" />
                    </div>
                </div>
            )}
            <div className={styles.rightSide}>
                <div className={styles.signInPanelRoot}>
                    {isMobile && <Image src={logo} alt="DVC For Less logo" className={styles.loginModalLogo} />}
                    <h3 className={styles.rightSideTitle}>{state.showForgotPassword ? "Reset Password" : "Sign In"}</h3>
                    <p className={styles.rightSubSideTitle}>
                        {state.showForgotPassword ? "We will send you an email to reset your password" : "Sign in and continue looking for the ideal contract"}
                    </p>
                    <Form form={form} className={settingsStyles.personalForm} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    type: "email"
                                }
                            ]}>
                            <Input autoComplete="email" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            requiredMark={"optional"}
                            className={styles.passField}
                            rules={state.showForgotPassword ? null : passRules}
                            style={state.showForgotPassword ? { display: "none" } : {}}>
                            <Input.Password autoComplete="current-password" disabled={state.showForgotPassword} />
                        </Form.Item>

                        <Form.Item className="last">
                            <Button loading={isLoading} htmlType="submit" className={styles.signInPanelBtn}>
                                {state.showForgotPassword ? "Reset Password" : "Sign In"}
                            </Button>
                        </Form.Item>
                    </Form>

                    <div className={styles.signInPanelDivider}>or</div>
                    <div className={styles.signInPanelOverIcon}>
                        {/*                <button aria-label="Facebook login" className={styles.signInPanelIconBtn} onClick={handler({ provider: "Facebook" })}
                        children={<Facebook />} />*/}
                        <button aria-label="Google login" className={styles.signInPanelIconBtn} onClick={() => thirdPartySignInUp({ provider: "google" })} children={<Google />} />
                        <button
                            aria-label="Apple login"
                            className={`${styles.signInPanelIconBtn} ${styles.appleButton}`}
                            onClick={() => thirdPartySignInUp({ provider: "apple" })}
                            children={<Apple />}
                        />
                    </div>
                    <div className={styles.signUpLine}>
                        New User ? <button onClick={toggleSignInUp}>Sign Up</button>
                    </div>
                    <div className={styles.forgotPassword}>
                        <a onClick={forgotPassword}>{state.showForgotPassword ? "Back to Sign In" : "Forgot password?"}</a>
                    </div>
                </div>
            </div>
        </>
    )
}
