const port = process.env.APP_PORT || 3000

const websiteBasePath = "/auth"
const apiBasePath = "/api/auth"

export const websiteDomain = process.env.APP_URL || process.env.NEXT_PUBLIC_APP_URL || `http://localhost:${port}`
export const apiDomain = process.env.NEXT_PUBLIC_AUTH_API_URL || websiteDomain
export const dvcApiDomain = process.env.DVC_API_URL
export const appInfo = {
    // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
    appName: "DVC For Less",
    websiteDomain,
    apiDomain,
    apiBasePath,
    websiteBasePath
}
