/**
 * @type {import("rc-field-form/lib/interface").Rule[]}
 */
export const emailRules = [
    {
        type: "email",
        required: true,
        message: "Email is not valid"
    }
]

/**
 * @type {import("rc-field-form/lib/interface").Rule[]}
 */
export const passRules = [
    {
        pattern: /^(?=.*\d).{8,}$/,
        required: true,
        message: "Password must contain at least 8 characters, including a number"
    }
]
// https://developer.apple.com/password-rules/
export const passRulesDefinition = "minlength: 8; required: digit"

/**
 * @type {import("rc-field-form/lib/interface").Rule[]}
 */
export const nameRules = [
    {
        min: 2,
        required: true,
        message: "Must contain at least 2 characters"
    }
]
