type FlattenWhereProps = {
    price?: {
        lte?: number
    }
    price_per_point?: {
        lte?: number
    }
    points_on_contract?: {
        lte?: number
        gte?: number
    }
    use_month?: {
        in?: string[]
    }
    status?: {
        in?: string[]
        notIn?: string[]
    }
    origin?: {
        in?: string[]
    }
    stars?: {
        gte?: number
    }
    resort?: {
        short_name?: {
            in?: string[]
        }
    }
}
export function flattenWhere(where: FlattenWhereProps): ExpandWhereProps {
    let res = {
        price_lte: where?.price?.lte,
        price_per_point_lte: where?.price_per_point?.lte,
        points_on_contract_lte: where?.points_on_contract?.lte,
        points_on_contract_gte: where?.points_on_contract?.gte,
        use_month_in: where?.use_month?.in,
        status_in: where?.status?.in,
        origin_in: where?.origin?.in,
        stars_gte: where?.stars?.gte,
        status_nin: where?.status?.notIn,
        resort: undefined
    }
    if (where?.resort?.short_name) {
        res.resort = {
            short_name_in: where?.resort?.short_name.in || []
        }
    }

    return clean(res)
}

export type ExpandWhereProps = {
    price_lte?: number
    price_per_point_lte?: number
    points_on_contract_lte?: number
    points_on_contract_gte?: number
    use_month_in?: string[]
    status_in?: string[]
    origin_in?: string[]
    stars_gte?: number
    status_nin?: string[]
    resort?:
        | {
              short_name_in: string[]
          }
        | number
}

export function expandWhere(where: ExpandWhereProps, withPrefix = false) {
    const prefix = withPrefix ? "$" : ""
    let res = {
        price: { [prefix + "lte"]: where.price_lte },
        price_per_point: { [prefix + "lte"]: where.price_per_point_lte },
        points_on_contract: {
            [prefix + "lte"]: where.points_on_contract_lte,
            [prefix + "gte"]: where.points_on_contract_gte
        },
        use_month: {
            [prefix + "in"]: where.use_month_in
        },
        status: {
            [prefix + "in"]: where.status_in,
            [prefix + "notIn"]: where.status_nin
        },
        origin: {
            [prefix + "in"]: where.origin_in
        },
        stars: {
            [prefix + "gte"]: where?.stars_gte
        },
        resort: undefined
    }

    if (typeof where?.resort === "number") {
        res.resort = {
            id: {
                [prefix + "eq"]: where?.resort
            }
        }
    } else if (where?.resort?.short_name_in) {
        res.resort = {
            short_name: {
                [prefix + "in"]: where?.resort?.short_name_in || []
            }
        }
    }
    return clean(res)
}

function clean<T extends object>(object: T): T {
    Object.entries(object).forEach(([k, v]) => {
        if (v && typeof v === "object") {
            clean(v)
        }
        if ((v && typeof v === "object" && !Object.keys(v).length) || v === null || v === undefined) {
            if (Array.isArray(object)) {
                object.splice(Number(k), 1)
            } else {
                delete object[k]
            }
        }
    })
    return object
}
