import { Modal, notification } from "antd"
import { useEffect, useState } from "react"
import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-auth-react/recipe/thirdparty"
import { appInfo } from "config/appInfo"
import { keys, store } from "@utils/store"
import { SignInPanel } from "../../auth/SignInPanel"
import { SignUpPanel } from "../../auth/SignUpPanel"
import { Media } from "../../Media"
import { Close } from "@css/icons/SVG"
import styles from "@css/modules/Layout.module.scss"

export const closeModal = () => store.set(keys.loginModalVisible, { isVisible: false })
export const openModal = (newPath = null, showSignUp = null) => store.set(keys.loginModalVisible, { isVisible: true, location: newPath, showSignUp })

const maskStyle = {
    background: "rgba(204, 120, 255, 0.13)",
    backdropFilter: "blur(37px)"
}

export function LoginModal() {
    const [visible, setVisible] = useState(() => {
        return {
            isVisible: store.get(keys.loginModalVisible) || false,
            location: null
        }
    })
    const hasLoggedIn = function () {
        let hasLoggedInBefore = false
        if (typeof document !== "undefined") {
            hasLoggedInBefore = localStorage.getItem("hasLoggedInBefore") === "1"
        }
        return hasLoggedInBefore
    }
    const [showSignIn, setShowSignIn] = useState(() => hasLoggedIn())

    const toggleSignInUp = () => {
        setShowSignIn(!showSignIn)
    }

    const thirdPartySignInUp = async function ({ provider }) {
        try {
            let frontendRedirectURI = `${appInfo.websiteDomain}${appInfo.websiteBasePath}/callback/${provider}`
            let params = {
                thirdPartyId: provider,

                // This is where Google should redirect the user back after login or error.
                // This URL goes on the Google's dashboard as well.
                frontendRedirectURI: frontendRedirectURI
            }
            // Apple is handled specially on the server
            if (provider === "apple") {
                // This URL goes on the Apple's dashboard
                params.redirectURIOnProviderDashboard = `${appInfo.apiDomain}${appInfo.apiBasePath}/callback/${provider}`
            }
            let authUrl = await getAuthorisationURLWithQueryParamsAndSetState(params)

            localStorage.setItem("redirectAfterLogin", typeof visible?.location === "string" ? visible?.location : location.pathname + location.search)
            // we redirect the user to third party for auth.
            window.location.assign(authUrl)
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                notification.open({
                    type: "error",
                    message: err.message
                })
            } else {
                notification.open({
                    type: "error",
                    message: "Oops! Something went wrong."
                })
            }
        }
    }

    useEffect(() => {
        const unwatch = store.watch(keys.loginModalVisible, ({ isVisible, showSignUp, location }) => {
            setVisible({
                isVisible,
                location: location || null
            })
            setShowSignIn(showSignUp ? !showSignUp : hasLoggedIn())
        })
        return () => {
            unwatch()
        }
    }, [])
    return (
        <Media
            query="(max-width: 900px)"
            render={(isMobile) => {
                return (
                    <Modal
                        maskStyle={maskStyle}
                        wrapClassName={styles.loginModalWrap}
                        className={styles.loginModal}
                        width={isMobile ? 500 : 1060}
                        closeIcon={<Close className={styles.closeIcon} />}
                        centered
                        open={visible.isVisible}
                        maskClosable
                        onCancel={closeModal}
                        footer={null}>
                        <>
                            {showSignIn ? (
                                <SignInPanel isMobile={isMobile} thirdPartySignInUp={thirdPartySignInUp} toggleSignInUp={toggleSignInUp} onClose={closeModal} size="default" />
                            ) : (
                                <SignUpPanel isMobile={isMobile} thirdPartySignInUp={thirdPartySignInUp} toggleSignInUp={toggleSignInUp} onClose={closeModal} size="default" />
                            )}
                        </>
                    </Modal>
                )
            }}
        />
    )
}
